import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpRequestPath } from '../constants/HttpRequestPath';
import { IHttpResponce } from '../interfaces/IHttpResponce';
import { IUser } from '../interfaces/IUser';

@Injectable({
	providedIn: 'root'
})
export class UsersService {
	constructor(private http: HttpClient) {}

	/**
	 * @description get frozone users
	 */
	public getUsers() {
		return this.http.post<IHttpResponce<IUser[]>>(HttpRequestPath.GetUsers, {});
	}

	public createUser(user: Partial<IUser>) {
		return this.http.post(HttpRequestPath.CreateAccount, {
			data: { ...user }
		});
	}
}

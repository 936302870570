export enum ExtTypes {
	MATERIAL = 'Material',
	UNIT = 'Unit',
	BRAND = 'Brand',
	SERIES = 'Series',
	RepairsMaterialsParts = 'MaterialAndParts',
	SUPPLIERS_LIST = 'SupplierList',
	PARTS_CATEGORIES = 'PartsCategories',
	TYPE_UNIT = 'TypeUnit',
}
